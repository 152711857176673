<template>
  <div></div>
</template>

<script>
import routeTypes from '@/router/routeTypes';
import { mixin as mixinHelperUtils, mixinAuth, isMobile } from '@/common/helperUtils';
if (isMobile) {
  import('@/assets/m_css/reset.css');
  import('@/assets/m_css/common.css');
}


export default {
  name: 'NaverLoginCallback',
  mixins: [mixinHelperUtils, mixinAuth],
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => value.match(/(signIn|signUp)/),
    },
  },
  data() {
    return {
      naverLogin: null,
    };
  },
  mounted() {
    if (!window.naver) {
      // 네이버 아이디로 로그인 SDK : https://developers.naver.com/docs/login/sdks/ , https://developers.naver.com/docs/login/web/
      // SAMPLE PAGE : http://static.nid.naver.com/oauth/sample/javascript_sample.html
      const script = document.createElement('script');
      script.setAttribute('src', 'https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.0.js');
      script.addEventListener('load', () => {
        let path = '';
        if (this.type === 'signIn') {
          path = routeTypes.ROUTE_PATH_NAVER_CALLBACK_SIGNIN;
        } else if (this.type === 'signUp') {
          path = routeTypes.ROUTE_PATH_NAVER_CALLBACK_SIGNUP;
        }

        this.naverLogin = new window.naver.LoginWithNaverId({
          clientId: process.env.VUE_APP_NAVER_CLIENT_ID,
          callbackUrl: window.location.origin + path,
          callbackHandle: true,
        });

        this.naverLogin.init();

        this.naverLogin.getLoginStatus((status) => {
          if (status) {
            const userInfo = {
              email: this.naverLogin.user.email,
              name: this.naverLogin.user.name,
              snsName: this.$t('sns.naver'),
              apply_path: '1103',
              access_token: this.naverLogin.accessToken.accessToken,
            };

            if (this.type === 'signIn') {
              this.signIn(userInfo); // mixinAuth
            } if (this.type === 'signUp') {
              this.signUp(userInfo); // mixinAuth
            }
          }
        });
      });

      document.head.appendChild(script);
    }
  },
};
</script>
